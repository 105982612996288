// This is a placeholder, not currently used.
// see betagouv/mon-entreprise to manage sitePaths for i18n
const sitePath = {
	index: '',
	documentation: {
		index: '/documentation',
		exemples: '/exemples',
	},
	contact: '/contact',
}

export default () => sitePath
